import { lazy, Suspense } from "react";
import { Route, Routes, Navigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ROLE_BACKOFFICE_ACCOUNT_MANAGER,
  ROLE_BACKOFFICE_FLIGHT,
  ROLE_BACKOFFICE_OPERATION,
  ROLE_BACKOFFICE_SUPERVISOR,
  ROLE_BACKOFFICE_VISA,
  ROLE_COMPANY_MANAGER,
  ROLE_COMPANY_TRAVEL_APPROVER,
  ROLE_COMPANY_TRAVEL_ARRANGER,
  ROLE_COMPANY_USER,
  ROLE_SYSTEM,
} from "./constants/Roles";
import { getRoleRoutePrefix } from "./service/RoutePath";
import { useQueryParams } from "./service/QueryParse";

import LoadingPage from "./components/app/LoadingPage";
import useScroll from "./hooks/useScroll";

const PickRole = lazy(() => import("./view/PickRole"));
const SignIn = lazy(() => import("./view/SignIn"));

//Frontend
const FrontendRoutes = lazy(() => import("./view/Frontend/FrontendRoutes"));

const Layout = lazy(() => import("./layout/default/Layout"));
const CompanyRoutes = lazy(() => import("./view/Company/CompanyRoutes"));
const SystemRoutes = lazy(() => import("./view/System/SystemRoutes"));
const BackOfficeRoutes = lazy(() =>
  import("./view/BackOffice/BackOfficeRoutes")
);
const Redirect = lazy(() => import("./view/Redirect"));
const Approval = lazy(() => import("./view/Approval"));

function App() {
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const twoFactorLoggedIn = useSelector(
    (state) => state.twoFactorAuth.twoFactorLoggedIn
  );
  const pickedRole = useSelector((state) => state.auth.userRole);
  const logOut = useSelector((state) => state.auth.logOut);
  const [approvalState, setApprovalState] = useSearchParams();
  const routePrefix = getRoleRoutePrefix(pickedRole);
  const {pathname} = useScroll()
  
  console.log(twoFactorLoggedIn);
  // if ((!loggedIn && !twoFactorLoggedIn) || (loggedIn && !twoFactorLoggedIn)) {
  //   return (
  //     <>
  //       <Suspense fallback={<LoadingPage />}>
  //         <ScrollToTop />
  //         <FrontendRoutes />
  //       </Suspense>
  //     </>
  //   );
  // }

  if (!loggedIn) {
    return (
      <>
        <Suspense fallback={<LoadingPage />}>
          <FrontendRoutes />
        </Suspense>
      </>
    );
  }

  // if (twoFactorLoggedIn && loggedIn && pickedRole === null) {
  //   return (
  //     <>
  //       <Suspense fallback={<LoadingPage />}>
  //         <PickRole />
  //       </Suspense>
  //     </>
  //   );
  // }

  if (loggedIn && pickedRole === null) {
    return (
      <>
        <Suspense fallback={<LoadingPage />}>
          <PickRole />
        </Suspense>
      </>
    );
  }

  if (
    loggedIn &&
    // twoFactorLoggedIn &&
    localStorage.getItem("user_role") === undefined &&
    localStorage.getItem("access_token") === undefined
  ) {
    logOut();
  }

  return (
    <div style={{ minHeight: "100vh" }}>
      <Suspense fallback={<LoadingPage />}>
        <Layout>
          <Routes>
            <Route path="/login" element={<Redirect />} />
            {/* <Route
              path={`/${routePrefix}approval?type=flight&message=approval`}
              element={<Approval />}
            /> */}

            {[
              ROLE_COMPANY_MANAGER,
              ROLE_COMPANY_USER,
              ROLE_COMPANY_TRAVEL_ARRANGER,
              ROLE_COMPANY_TRAVEL_APPROVER,
            ].includes(pickedRole) && (
              <Route path="company/*" element={<CompanyRoutes />} />
            )}

            {pickedRole === ROLE_SYSTEM && (
              <Route path="system/*" element={<SystemRoutes />} />
            )}

            {[
              ROLE_BACKOFFICE_ACCOUNT_MANAGER,
              // ROLE_BACKOFFICE_ACCOUNT_USER,
              ROLE_BACKOFFICE_FLIGHT,
              ROLE_BACKOFFICE_OPERATION,
              ROLE_BACKOFFICE_VISA,
              ROLE_BACKOFFICE_SUPERVISOR,
            ].includes(pickedRole) && (
              <Route path="back-office/*" element={<BackOfficeRoutes />} />
            )}
            <Route path="/*" element={<Redirect />} />
          </Routes>
        </Layout>
      </Suspense>
    </div>
  );
}

export default App;
