import React, { useContext, useState, useNavigate } from "react";
import { MENU_LINKS_EN, MENU_LINKS_TR } from "../../constants/MenuLinks";
import { useSelector } from "react-redux";
const FrontEndContext = React.createContext();

export const FrontEndProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const language = useSelector((state) => state.language.chosenLanguage);
  const [isBackendSideMenuOpen, setIsBackendSideMenuOpen] = useState(false);
  const [page, setPage] = useState({ page: "", pageInfo: "", content: [] });

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const openSubmenu = (text) => {
    let page;
    if (language == "en") {
      page = MENU_LINKS_EN.find((item) => item.page === text);
    } else {
      page = MENU_LINKS_TR.find((item) => item.page === text);
    }
    setPage(page);
    setIsSubmenuOpen(true);
  };

  const closeSubmenu = () => {
    setIsSubmenuOpen(false);
  };

  const closeBackendSideMenu = () => {
    setIsBackendSideMenuOpen(false);
  };
  const openBackendSideMenu = () => {
    setIsBackendSideMenuOpen(true);
    console.log("bas");
  };

  return (
    <FrontEndContext.Provider
      value={{
        isSidebarOpen,
        isSubmenuOpen,
        closeSidebar,
        openSidebar,
        openSubmenu,
        closeSubmenu,
        page,
        openBackendSideMenu,
        closeBackendSideMenu,
        isBackendSideMenuOpen,
      }}
    >
      {children}
    </FrontEndContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(FrontEndContext);
};
