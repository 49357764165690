import React, { useContext, useState } from "react";

const BackendContext = React.createContext();

export const BackendProvider = ({ children }) => {
  const [hasPersonSent, setHasPersonSent] = useState(false);
  const [isSecondAsideOpen, setIsSecondAsideOpen] = useState(false);
  const [isAsideOpen, setIsAsideOpen] = useState(false);
  const [hotelBookingParams, setHotelBookingParams] = useState({});

  const [companyId, setCompanyId] = useState();

  const openSecondAsideHandler = () => {
    setIsSecondAsideOpen(true);
  };

  const closeSecondAsideHandler = () => {
    setIsSecondAsideOpen(false);
  };

  const closeAsideHandler = () => {
    setIsAsideOpen(false);
  };

  const openAsideHandler = () => {
    setIsAsideOpen(true);
  };

  const HandleHasPersonSent = (status) => {
    setHasPersonSent(status);
  };

  const handleCompanyId = (id) => {
    setCompanyId(id);
  };

  return (
    <BackendContext.Provider
      value={{
        isSecondAsideOpen,
        openSecondAsideHandler,
        closeSecondAsideHandler,
        hasPersonSent,
        HandleHasPersonSent,
        hotelBookingParams,
        setHotelBookingParams,
        companyId,
        handleCompanyId,
        openAsideHandler,
        closeAsideHandler,
        isAsideOpen,
      }}
    >
      {children}
    </BackendContext.Provider>
  );
};

export const useGlobalBackendContext = () => {
  return useContext(BackendContext);
};
