import React from "react";
import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import "../../styles/Backend/components/LoadingPage.css";

const LoadingPage = () => {
  return (
    <div className="d-flex position-absolute w-100 h-100 bottom-0 top-0 align-items-center justify-content-center mh-100 loading-page_body">
      <div className="loader">
        {/* <BeatLoader
        style={{ backgroundColor: "var(--dark-blue-btn) !important" }}
      /> */}
        {/* <span style="--i:1;"></span>
      <span style="--i:2;"></span>
      <span style="--i:3;"></span>
      <span style="--i:4;"></span>
      <span style="--i:5;"></span>
      <span style="--i:6;"></span>
      <span style="--i:7;"></span>
      <span style="--i:8;"></span>
      <span style="--i:9;"></span>
      <span style="--i:10;"></span>
      <span style="--i:11;"></span>
      <span style="--i:12;"></span>
      <span style="--i:13;"></span>
      <span style="--i:14;"></span>
      <span style="--i:15;"></span>
      <span style="--i:16;"></span>
      <span style="--i:17;"></span>
      <span style="--i:18;"></span>
      <span style="--i:19;"></span>
      <span style="--i:20;"></span> */}
        <span
          style={{
            transform: "rotate(calc(18deg * 1))",
          }}
          className="span-one"
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 2))",
          }}
          className="span-two"
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 3))",
            animationDelay: "0.3s",
          }}
          className="span-one"
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 4))",
            animationDelay: "0.4s",
          }}
          className="span-one"
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 5))",
            animationDelay: "0.5s",
          }}
          className="span-one"
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 6))",
            animationDelay: "0.6s",
          }}
          className="span-one"
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 7))",
            animationDelay: "0.7s",
          }}
          className="span-one"
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 8))",
            animationDelay: "0.8s",
          }}
          className="span-one"
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 9))",
            animationDelay: "0.9s",
          }}
          className="span-one"
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 10))",
            animationDelay: "1s",
          }}
          className="span-one"
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 11))",
            animationDelay: "1.1s",
          }}
          className="span-one"
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 12))",
            animationDelay: "1.2s",
          }}
          className="span-one"
        >
          {" "}
        </span>
        <span
          style={{
            transform: "rotate(calc(18deg * 13))",
            animationDelay: "1.3s",
          }}
          className="span-one"
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 14))",
            animationDelay: "1.4s",
          }}
          className="span-one"
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 15))",
            animationDelay: "1.5s",
          }}
          className="span-one"
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 16))",
            animationDelay: "1.6s",
          }}
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 17))",
            animationDelay: "1.7s",
          }}
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 18))",
            animationDelay: "1.8s",
          }}
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 19))",
            animationDelay: "1.9s",
          }}
        ></span>
        <span
          style={{
            transform: "rotate(calc(18deg * 20))",
            animationDelay: "2s",
          }}
        ></span>
        <i className="plane-icon-container">
          <FontAwesomeIcon icon={faPlane} className="plane-spinner" />
        </i>
      </div>
    </div>
  );
};

export default LoadingPage;
