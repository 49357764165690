export const getRoleRoutePrefix = (role) => {
  const routePrefix = {
    ROLE_COMPANY_MANAGER: "company/manager",
    ROLE_COMPANY_TRAVEL_APPROVER: "company/travel-approver",
    ROLE_COMPANY_USER: "company/user",
    ROLE_COMPANY_TRAVEL_ARRANGER: "company/travel-arranger",
    ROLE_SYSTEM: "system",
    ROLE_BACKOFFICE_FLIGHT: "back-office/flight",
    ROLE_BACKOFFICE_OPERATION: "back-office/operation",
    ROLE_BACKOFFICE_SUPERVISOR: "back-office/supervisor",
    ROLE_BACKOFFICE_VISA: "back-office/visa",
  };

  return routePrefix[role] ? routePrefix[role] : "";
};
