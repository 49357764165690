import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./reducer";

const persistenceConfigs = {
  key: "atgplus",
  storage,
  whitelist: ["auth", "language", "twoFactorAuth", "flightStore"],
};

const persistedReducer = persistReducer(persistenceConfigs, rootReducer);

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));

const store = createStore(persistedReducer, composedEnhancer);
export default store;

export const persistedStore = persistStore(store);
