import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./styles/index.css";
import "./plugins/api";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistedStore } from "./store/store";
import { FrontEndProvider } from "./store/context/frontend-context";
import "./assets/sass/plugins.scss";
import "./assets/sass/style.scss";
import "./assets/sass/style.react.scss";
import { BackendProvider } from "./store/context/backend-context";
import "./service/Translations/i18n";

ReactDOM.render(
  <React.Suspense fallback="loading">
    <Provider store={store}>
      <FrontEndProvider>
        <BackendProvider>
          <PersistGate loading={null} persistor={persistedStore}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PersistGate>
        </BackendProvider>
      </FrontEndProvider>
    </Provider>
  </React.Suspense>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
