import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate, Routes, Route } from "react-router-dom";
import axios from "../../../plugins/api";
import { URL } from "../../../constants/General";
import React, { lazy } from "react";

export const twoFactorLogin = createAsyncThunk(
  "auth/twoFactorLogin",
  async (credentials, thunkAPI) => {
    const response = await axios.post(URL + "/api/login/2fa_check", {
      authCode: credentials.authCode,
      trustedDevice: credentials.trustedDevice,
    });
    // console.log(response);
    return response.data;
  }
);

const twoFactorAuthSlice = createSlice({
  name: "twoFactorAuth",
  initialState: {
    twoFactorLoggedIn: false,
    loading: false,
    token: null,
    userRole: null,
    user: null,
    avatar: null,
    company: null,
    userId: null,
  },
  reducers: {
    twoFactorLoggedInHandle(state) {
      state.twoFactorLoggedIn = true;
    },
  },
  extraReducers: {
    [twoFactorLogin.pending]: (state, action) => {
      state.loading = true;
      // state.loginError = false;
      // cleanUpAuthState(state);
      console.log("pendinbg");
    },
    [twoFactorLogin.fulfilled]: (state, action) => {
      state.twoFactorLoggedIn = true;
      state.loading = false;
      state.token = action.payload.token;
      state.userRole = action.payload.role;
      localStorage.setItem("user_role", action.payload.role);
      localStorage.setItem("access_token", action.payload.token);
      // state.loginError = false;
      console.log("fullfill");
      if (action.payload.user !== undefined) {
        state.avatar = action.payload.user.avatar;
        state.company = action.payload.user.company;
        if (action.payload.user.person !== null) {
          state.userId = action.payload.user.person.id;
        } else {
          state.userId = action.payload.user.user.id;
        }
      }
      state.userRole = action.payload.role;
    },
    [twoFactorLogin.rejected]: (state, action) => {
      console.log(action);
      state.loading = false;
      // state.loginError = true;
    },
  },
});

export const { twoFactorLoggedInHandle } = twoFactorAuthSlice.actions;

export default twoFactorAuthSlice.reducer;
