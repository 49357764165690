import React from "react";
import { useLocation } from "react-router-dom";

export const useQueryParams = () => {
  const { search } = useLocation();

  return React.useMemo(() => {
    let params = {};
    new URLSearchParams(search).forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }, [search]);
};
