import {
  faPlane,
  faFileAlt,
  faIdCard,
  faCheckSquare,
  faBook,
  faPeopleArrows,
  faUserSecret,
  faUnlockAlt,
  faGlobe,
  faList,
  faShield,
  faEnvelope,
  faTools,
  faThumbsUp,
  faMapLocationDot,
  faLink,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";

const MENU_LINKS_EN = [
  {
    page: "Products",
    pageInfo:
      "With our Travel Platform and Plus Analytics, you can speed up transactions for your company and increase your efficiency.",
    content: [
      {
        contentIcon: faPlane,
        contentName: "Travel Platform",
        contentLink: "travel-platform",
        contentInfo: "The easiest way to plan your trip.",
        contentCode: "travel-platform",
      },
      {
        contentIcon: faFileAlt,
        contentName: "Reporting Tools",
        contentLink: "reporting-tools",

        contentInfo: "A fine solution for managing your travel expenses.",
        contentCode: "reporting-tools",
      },
    ],
  },
  {
    page: "Solutions",
    pageInfo:
      "Make your organization faster and more efficient with the cutting-edge solutions we offer.",
    pageLink: "solutions",
    content: [
      {
        contentIcon: faIdCard,

        contentName: "Profile Management",
        contentInfo:
          "Use the platform to create, update, and delete profiles directly without having to access each user separately.",
        contentLink: "solutions",
        contentCode: "profile-management",
      },
      {
        contentIcon: faMapLocationDot,

        contentName: "Travel Policy Management",
        contentInfo:
          "You can create as many travel policies as you need and assign them to the members of your team.",
        contentLink: "solutions",
        contentCode: "travel-policy",
      },
      {
        contentIcon: faThumbsUp,

        contentName: "Approval Processes",
        contentInfo:
          "Without a phone call or sending an email, the approver can approve tickets with one click for the employee.",
        contentLink: "solutions",
        contentCode: "approval-processes",
      },
      {
        contentIcon: faPeopleArrows,
        contentName: "Group Travel",
        contentInfo: "Organize trips for hundreds of people at a time.",
        contentLink: "solutions",
        contentCode: "group-travel",
      },
      ,
      {
        contentIcon: faPeopleGroup,
        contentName: "Meetings & Organisations",
        contentInfo: "Bring people together and design experiences.",
        contentLink: "solutions",
        contentCode: "meetings-events",
      },
    ],
  },
  // {
  //   page: "Resources",
  //   pageInfo:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
  //   content: [
  //     { contentName: "Flexible Booking Options", contentInfo: "" },
  //     { contentName: "Significant Cost Saving", contentInfo: "" },
  //     { contentName: "Around the Globe Support", contentInfo: "" },
  //   ],
  // },
  {
    page: "Company",
    pageInfo:
      "You can find what you are wondering about our company in this section.",
    pageLink: "company",
    content: [
      {
        contentIcon: faGlobe,
        contentName: "About Us",
        contentInfo: "Learn more information about our company.",
        contentLink: "about-us",
        contentCode: "about-us",
      },
      {
        contentIcon: faUnlockAlt,
        contentName: "Security",
        contentInfo:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.",
        contentLink: "company",
        contentCode: "security",
      },
      {
        contentIcon: faUserSecret,

        contentName: "Privacy Policy",
        contentInfo:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.",
        contentLink: "company",
        contentCode: "privacy-policy",
      },
      {
        contentIcon: faList,
        contentName: "Terms & Conditions",
        contentInfo:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.",
        contentLink: "company",
        contentCode: "terms-conditions",
      },
      {
        contentIcon: faShield,
        contentName: "Protection Regulation",
        contentInfo:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.",
        contentLink: "company",
        contentCode: "protection-regulation",
      },
    ],
  },
  {
    page: "Contact",
    pageInfo:
      "Do you have a question about our product or need any help? Please do not hesitate to contact.",
    pageLink: "contact",
    content: [
      {
        contentIcon: faEnvelope,
        contentName: "Contact",
        contentInfo: "Contact Us",
        contentLink: "contact",
        contentCode: "contact",
      },
      {
        contentIcon: faTools,
        contentName: "Support",
        contentInfo: "Do you need any help?",
        contentLink: "support",
        contentCode: "support",
      },
      {
        contentIcon: faLink,
        contentName: "Become Our Supplier",
        contentInfo: "Work with us.",
        contentLink: "become-a-supplier",
        contentCode: "become-a-supplier",
      },
    ],
  },
];

const MENU_LINKS_TR = [
  {
    page: "Ürünler",
    pageInfo:
      "Seyahat Platformumuz ve Plus Analytics ile şirketiniz için işlemleri hızlandırandırıp veriminizi arttırabilirsiniz.",
    content: [
      {
        contentIcon: faPlane,
        contentName: "Seyahat Platformu",
        contentLink: "travel-platform",
        contentInfo: "Seyahatinizi planlamanın en kolay yolu.",
        contentCode: "travel-platform",
      },
      {
        contentIcon: faFileAlt,
        contentName: "Raporlama Araçları",
        contentLink: "reporting-tools",

        contentInfo: "Seyahat masraflarınızı yönetmek için iyi bir çözüm.",
        contentCode: "reporting-tools",
      },
    ],
  },
  {
    page: "Çözümler",
    pageInfo:
      "Sunduğumuz son teknoloji çözümlerle kuruluşunuzu daha hızlı ve daha verimli hale getirin.",
    pageLink: "solutions",
    content: [
      {
        contentIcon: faIdCard,

        contentName: "Profil Yönetimi",
        contentInfo:
          "Her bir kullanıcıya ayrı ayrı erişmek zorunda kalmadan doğrudan profil oluşturmak, güncellemek ve silmek için platformu kullanabilirsiniz.",
        contentLink: "solutions",
        contentCode: "profile-management",
      },
      {
        contentIcon: faMapLocationDot,

        contentName: "Seyahat Politikası Yönetimi",
        contentInfo:
          "İstediğiniz kadar seyahat poliçesi oluşturabilir ve bunları ekibinizin üyelerine atayabilirsiniz.",
        contentLink: "solutions",
        contentCode: "travel-policy",
      },
      {
        contentIcon: faThumbsUp,

        contentName: "Onay Süreci",
        contentInfo:
          "Bir telefon görüşmesi veya e-posta göndermeden, onaylayan kişi, çalışan için biletleri tek tıklamayla onaylayabilir.",
        contentLink: "solutions",
        contentCode: "approval-processes",
      },
      {
        contentIcon: faPeopleArrows,
        contentName: "Grup Seyahati",
        contentInfo: "Bir seferde yüzlerce insan için seyahat düzenleyin.",
        contentLink: "solutions",
        contentCode: "group-travel",
      },
      {
        contentIcon: faPeopleGroup,
        contentName: "Toplantılar ve Etkinlikler",
        contentInfo: "İnsanları bir araya getirin ve deneyimler tasarlayın.",
        contentLink: "solutions",
        contentCode: "meetings-events",
      },
    ],
  },
  // {
  //   page: "Resources",
  //   pageInfo:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
  //   content: [
  //     { contentName: "Flexible Booking Options", contentInfo: "" },
  //     { contentName: "Significant Cost Saving", contentInfo: "" },
  //     { contentName: "Around the Globe Support", contentInfo: "" },
  //   ],
  // },
  {
    page: "Şirket",
    pageInfo:
      "Şirketimiz hakkında merak ettiklerinizi bu kısımda bulabilirsiniz.",
    pageLink: "company",
    content: [
      {
        contentIcon: faGlobe,
        contentName: "Hakkımızda",
        contentInfo: "Şirketimiz hakkında daha çok şey öğrenin",
        contentLink: "about-us",
        contentCode: "about-us",
      },
      {
        contentIcon: faUnlockAlt,
        contentName: "Güvenlik",
        contentInfo: "Güvenliğinize önem veriyoruz.",
        contentLink: "company",
        contentCode: "security",
      },
      {
        contentIcon: faUserSecret,

        contentName: "Gizlilik Politikası",
        contentInfo: "Gizliliğinize",
        contentLink: "company",
        contentCode: "privacy-policy",
      },
      {
        contentIcon: faList,
        contentName: "Şartlar & Koşullar",
        contentInfo: "Şartlarımız",
        contentLink: "company",
        contentCode: "terms-conditions",
      },
      {
        contentIcon: faShield,
        contentName: "Koruma Yönetmeliği",
        contentInfo: "",
        contentLink: "company",
        contentCode: "protection-regulation",
      },
    ],
  },
  {
    page: "İletişim",
    pageInfo:
      "Ürünümüzle ilgili bir sorunuz veya yardıma mı ihtiyacınız var? Lütfen iletişim kurmaktan çekinmeyin.",
    pageLink: "contact",
    content: [
      {
        contentIcon: faEnvelope,
        contentName: "İletişim",
        contentInfo: "Bize Ulaşın",
        contentLink: "contact",
        contentCode: "contact",
      },
      {
        contentIcon: faTools,
        contentName: "Destek",
        contentInfo: "Yardıma ihtiyacınız mı var?",
        contentLink: "support",
        contentCode: "support",
      },
      {
        contentIcon: faLink,
        contentName: "Tedarikçimiz Olun",
        contentInfo: "Bizimle çalışın",
        contentLink: "become-a-supplier",
        contentCode: "become-a-supplier",
      },
    ],
  },
];

export { MENU_LINKS_EN, MENU_LINKS_TR };
