import axios from "axios";
import {configure as configureAxiosHooks} from "axios-hooks";
import {logOut} from "../store/slicers/auth/auth";
import store from "../store/store";
import Swal from "sweetalert2";
import {URL} from "../constants/General";

const api = axios.create({
  baseURL: URL,
});

api.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem("access_token");
    const userRole = localStorage.getItem("user_role");

    if (accessToken !== null) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }

    if (userRole !== null) {
        config.headers['x-user-role'] = userRole;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (401 === error.response.status) {
        store.dispatch(logOut({}));
      } else if (403 === error.response.status) {
          Swal.fire('Yetkisiz işlem.');
          return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

configureAxiosHooks({
  axios: api,
  cache: false,
});

export default api;
