import { combineReducers } from "redux";

import authReducer from "./slicers/auth/auth";
import backendMenuReducer from "./slicers/backend-menu";
import languageReducer from "./slicers/language/language";
import twoFactorAuthReducer from "./slicers/auth/twoFactorAuth";
import flightStoreReducer from "./slicers/flightStore/flightStore";

const rootReducer = combineReducers({
  auth: authReducer,
  twoFactorAuth: twoFactorAuthReducer,
  language: languageReducer,
  backendMenu: backendMenuReducer,
  flightStore: flightStoreReducer,
});

export default rootReducer;
