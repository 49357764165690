import React from "react";
import { createSlice } from "@reduxjs/toolkit";

const backendMenuSlice = createSlice({
  name: "backendMenu",
  initialState: {
    isResponsiveOpen: false,
    isSecondAsideOpen: false,
  },
  reducers: {
    openResponsiveMenu(state) {
      state.isSideMenuOpen = true;
    },
    closeResponsivemenu(state) {
      state.isSideMenuOpen = false;
    },
    openSecondAside(state) {
      state.isSecondAsideOpen = true;
    },
    closeSecondAside(state) {
      state.isSecondAsideOpen = false;
    },
  },
});

export const { openSideMenu, closeSidemenu } = backendMenuSlice.actions;

export default backendMenuSlice.reducer;
