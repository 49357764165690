export const ROLE_COMPANY_MANAGER = "ROLE_COMPANY_MANAGER";
export const ROLE_COMPANY_TRAVEL_ARRANGER = "ROLE_COMPANY_TRAVEL_ARRANGER";
export const ROLE_COMPANY_TRAVEL_APPROVER = "ROLE_COMPANY_TRAVEL_APPROVER";
export const ROLE_COMPANY_USER = "ROLE_COMPANY_USER";
export const ROLE_SYSTEM = "ROLE_SYSTEM";
export const ROLE_BACKOFFICE_OPERATION = "ROLE_BACKOFFICE_OPERATION";
export const ROLE_BACKOFFICE_FLIGHT = "ROLE_BACKOFFICE_FLIGHT";
// export const ROLE_BACKOFFICE_ACCOUNT_USER = "ROLE_BACKOFFICE_ACCOUNT_USER";
export const ROLE_BACKOFFICE_ACCOUNT_MANAGER =
  "ROLE_BACKOFFICE_ACCOUNT_MANAGER";
export const ROLE_BACKOFFICE_VISA = "ROLE_BACKOFFICE_VISA";
export const ROLE_BACKOFFICE_SUPERVISOR = "ROLE_BACKOFFICE_SUPERVISOR";
