import { createSlice } from "@reduxjs/toolkit";

const flightStoreSlice = createSlice({
  name: "flightStore",
  initialState: {
    reissuedModalOpen: false,
    reissued: 2,
    reissuedMode: false,
  },
  reducers: {
    handleReissueModalOpen(state) {
      state.reissuedModalOpen = true;
    },
    handleReissueModalClose(state) {
      state.reissuedModalOpen = false;
    },
    handleReissuedPassive(state) {
      state.reissued = 2;
    },
    handleReissuedActive(state) {
      state.reissued = 3;
    },
    handleOpenReissuedMode(state) {
      state.reissuedMode = true;
    },
    handleCloseReissuedMode(state) {
      state.reissuedMode = false;
    },
  },
  extraReducers: {},
});

export const {
  handleReissuedActive,
  handleReissuedPassive,
  handleReissueModalClose,
  handleReissueModalOpen,
  handleCloseReissuedMode,
  handleOpenReissuedMode,
} = flightStoreSlice.actions;

export default flightStoreSlice.reducer;
